import styled from 'styled-components'


export const TopBox = styled.div`

background: white;
width:100%;
height:80px;
margin: 10px 0px;

`;
export const ButnExcell = styled.div`
background-color:  var(--light-color);
border: 1px solid #8056f7;
border-radius: 4px;
`