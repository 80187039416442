import React from 'react'
import { VoucherReportTable } from './Partials/VoucherReportTable'

export const VoucherReport = () => {
  return (
    <div>
        <VoucherReportTable/>
    </div>
  )
}
