/* eslint-disable */
import React from 'react'
import AdminLayout from '../../../Layout/AdminMain'

const Page = ({ children }) => {

  return (
    <AdminLayout>
      {children}
    </AdminLayout>
  )
}
export default Page
