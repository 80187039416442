import React from 'react'
import { PurchaseEntryPage } from './Partials/PurchaseEntryPage'

export const AddPurchase = () => {
  return (
    <div style={{ margin: '0 auto', width: '95%' }}>
       <PurchaseEntryPage />
    </div>
  )
}

