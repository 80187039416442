import React from 'react'
import View_Attendance from './Partials/View_Attendance'

const ViewAttendance = () => {
  return (
    <div>
        <View_Attendance />
    </div>
  )
}

export default ViewAttendance