import React from 'react'
import { ReceiptReportTable } from './Partials/ReceiptReportTable'

export const ReceiptReport = () => {
  return (
    <div>
        <ReceiptReportTable/>
    </div>
  )
}
