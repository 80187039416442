export const SET_COMPANY = 'SET_COMPANY'


export const setCompany = value => {
  return {
    type: SET_COMPANY,
    payload: value,
  }
}

