import { Form, Col } from 'antd'
import { useForm } from 'antd/es/form/Form'
import React, { useEffect, useState } from 'react'
import { Row } from '../../../../../Components/Row'
import Input from '../../../../../Components/Form/Input'
import Flex from '../../../../../Components/Flex'
import Button from '../../../../../Components/Form/Button'
import { CustomInputNumber } from '../../../../../Components/Form/CustomInputNumber'
import { CustomDatePicker } from '../../../../../Components/Form/CustomDatePicker'
import dayjs from 'dayjs'
import { Select } from '../../../../../Components/Form/Select'
import { toast } from 'react-toastify'
import request from '../../../../../utils/request'
import { CustomSelect } from '../../../../../Components/Form/CustomSelect'
import { Modal } from '../../../../../Components/Modal'
import { AddBranchModal } from '../../AddBank/Partials/AddBranchModal'
import { AddBankModal } from '../../AddBank/Partials/AddBankModal'
import { AddPlatformModal } from '../../AddBank/Partials/AddPlatformModal'
import { CustomCardView } from '../../../../../Components/CustomCardView'
import Switch from '../../../../../Components/Form/Switch'
import { FormTitle } from '../../../../../Components/Form/FormTitle'

export const OnlineTransaction = () => {

  const [form] = useForm()

  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'))

  const [openModal, setOpenModal] = useState(false)

  const [modalTitle, setModalTitle] = useState("");

  const [modalContent, setModalContent] = useState(null);

  const [platform, setPlatform] = useState([])

  const [resetTrigger, setResetTrigger] = useState(0)

  const [click, setClick] = useState(true)

  const [accountNameSelect, setSccountNameSelect] = useState([])


  const options = [
    {
      label: 'Credit',
      value: 'credit',
    },
    {
      label: 'Debit',
      value: 'debit',
    },
  ]

  const onFinish = (value) => {
    const newValues = { ...value, date: selectedDate }
    form.resetFields()
    PostData(newValues)
    console.log(newValues, 'aaa');
  }

  const PostData = (value) => {
    request.post('bank/save', value)
      .then(response => {
        toast.success('Bank Details Added Successfully!')
      })
      .catch(error => console.log(error, 'error'))
  }

  const handleButtonClick = () => {
    setResetTrigger(resetTrigger + 1)
    setModalTitle('Add payment Platform')
    setModalContent(<AddPlatformModal resetTrigger={resetTrigger} handleOk={handleOk} />)
    showModal()
  }

  const handlePlatformIdChange = (e) => {
    // const selectedBank = bank?.find((item) => item.bankName === e)
    // setBankId(selectedBank.bankNameId)
  }

  const platformData = platform?.map(val => ({
    // label: val.bankName,
    // value: val.bankName
  }))



  const onFinishFailed = (value) => {
    console.log(value);
  }

  const showModal = () => {
    setOpenModal(true);
  };

  const handleOk = () => {
    setOpenModal(false);
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  const dateOnChange = (ddd) => {
    setSelectedDate(ddd)
  }

  const onReset = () => {
    form.resetFields()
  }

  const handleSwitched = (e) => {
    setClick(!click);
  };

  return (
    <CustomCardView>
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          date: dayjs()
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">
        <Row gutter={[24, 24]}>
          <Col span={24} md={12}>
            <Flex centerVertically >
              <span>Debit</span>&nbsp;
              <Switch onClick={handleSwitched} />
              &nbsp;<span>Credit</span>
            </Flex>
          </Col>
          <Col span={24} md={12}>
          </Col>
          <Col span={24} md={12}>
            <h5 style={{ letterSpacing: '1px' }}>Account Holder Details :</h5>
          </Col>
          <Col span={24} md={12}>
          </Col>
          <Col span={24} md={12}>
            <Select options={accountNameSelect} label={'Account Holder Name'} placeholder={'Select account holder'} name={'accountHolder'} rules={[
              {
                required: true,
                message: 'This field is required !'
              }
            ]} />
          </Col>
          <Col span={24} md={12}>
            <CustomInputNumber label={'Account Number'} disabled name={'accountNumber'} rules={[
              {
                required: true,
                message: 'This field is required !'
              }
            ]} />
          </Col>
          <Col span={24} md={12}>
            <Input
              label={'Bank Name'}
              name={'bankName'}
              disabled
              rules={[
                {
                  required: true,
                  message: 'This field is required !'
                }
              ]} />
            <Input name={'bankNameId'} display={'none'} />
          </Col>
          <Col span={24} md={12}>
            <Input
              label={'Branch Name'}
              name={'branchName'}
              disabled
              rules={[
                {
                  required: true,
                  message: 'This field is required !'
                }
              ]} />
          </Col>



          <Col span={24} md={12}>

          </Col>
          <Col span={24} md={12}>

          </Col>
          <Col span={24} md={12}>
            <h5 style={{ letterSpacing: '1px', marginTop: '10px' }}>Customer Details :</h5>
          </Col>
          <Col span={24} md={12}>
          </Col>
          <Col span={24} md={12}>
            <Select options={accountNameSelect} label={'Account Holder Name'} placeholder={'Select account holder'} name={'accountHolder'} rules={[
              {
                required: true,
                message: 'This field is required !'
              }
            ]} />
          </Col>
          <Col span={24} md={12}>
            <CustomInputNumber label={'Account Number'} disabled name={'accountNumber'} rules={[
              {
                required: true,
                message: 'This field is required !'
              }
            ]} />
          </Col>
          <Col span={24} md={12}>
            <Input
              label={'Bank Name'}
              name={'bankName'}
              disabled
              rules={[
                {
                  required: true,
                  message: 'This field is required !'
                }
              ]} />
          </Col>
          <Col span={24} md={12}>
            <Input
              label={'Branch Name'}
              name={'branchName'}
              disabled
              rules={[
                {
                  required: true,
                  message: 'This field is required !'
                }
              ]} />
          </Col>


          {/* <Col span={24} md={12}>
            <Select options={options} label={'Payment Type'} placeholder={'Select payment type'} name={'paymentType'} rules={[
              {
                required: true,
                message: 'This field is required !'
              }
            ]} />
          </Col> */}
          {/* <Col span={24} md={12}>
            <CustomSelect
              label={'Payment Platform'}
              placeholder={'Payment platform'}
              name={'paymentPlatform'}
              showSearch={true}
              onButtonClick={handleButtonClick}
              onChange={handlePlatformIdChange}
              value={platform}
              options={platformData}
              rules={[
                {
                  required: true,
                  message: 'This field is required !'
                }
              ]} />
            <Input name={'platformId'} display={'none'} />
          </Col> */}
          <Col span={24} md={12}>
            <CustomInputNumber precision={2} label={'Amount'} placeholder={'Enter amount'} name={'amount'} rules={[
              {
                required: true,
                message: 'This field is required !'
              }
            ]} />
          </Col>
          {/* <Col span={24} md={12}>
            <Input precision={2} label={'Phone Number'} placeholder={'Enter phone number'} name={'phoneNumber'} rules={[
              {
                required: true,
                message: 'This field is required !'
              }
            ]} />
          </Col> */}
          <Col span={24} md={12}>
            <CustomDatePicker label={'Date'} name={'date'} onChange={dateOnChange} rules={[
              {
                required: true,
                message: 'This field is required !'
              }
            ]} />
          </Col>
          <Flex center W_100 gap={'20px'}>
            <Button.Success text={'Save'} htmlType={'submit'} />
            <Button.Danger text={'Clear'} onClick={onReset} />
          </Flex>
        </Row>
      </Form>
      <Modal isVisible={openModal} handleCancel={handleCancel} handleOk={handleOk} modalTitle={modalTitle} modalContent={modalContent} />

    </CustomCardView>

  )
}
