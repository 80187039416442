import React, { useEffect } from 'react'
import { Table } from '../../../../../Components/Table'
import { Modal } from '../../../../../Components/Modal'
import request from '../../../../../utils/request'

export const ViewSalary = () => {

  useEffect(() => {
    getSalary()
  }, [])

  const getSalary = () => {
    request.get('home/get')
      .then(response => {
        console.log(response.data, 'SALARY');
      })
      .catch(error => console.log(error, 'error'))
  }


  const columns = [
    {
      title: 'SI No',
      render: (value, item, index) => index + 1,
    },
    {
      title: 'Payment Type',
      dataIndex: 'paymentType'
    },
    {
      title: 'Date',
      dataIndex: 'date'
    },
    {
      title: 'Amount',
      dataIndex: 'amount'
    },

  ]

  return (
    <div style={{ margin: '30px 0' }}>
      <Table columns={columns} />
    </div>
  )
}
