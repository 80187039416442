import styled from "styled-components";


export const Mainsection = styled.div`
  /* background: linear-gradient(90deg, rgba(225,87,255,1) 0%, rgba(121,9,107,1) 0%, rgba(40,67,215,1) 100%); */
  /* min-height: 100vh; */
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
  & h2{
    color: #fff;
    padding-left: 4px;
    display: flex;
    justify-content: center;
  }

`;
// export const Labels = styled(Label)`
// font-size: 15px;
// color: rgb(0 0 0 / 60%) !important;
// `;

export const Paradesn = styled.div`
& p {
  font-size: 15px;
  color: #939393fc;
}
`;