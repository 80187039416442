import React from 'react'
import ViewTable from './Partials/ViewTable'

const Admin_ViewSale = () => {
  return (
    <div>
        <ViewTable />
    </div>
  )
}

export default Admin_ViewSale