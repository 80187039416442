import { Col, Form, DatePicker } from "antd";
import { CustomDatePicker } from "../../../../../Components/Form/CustomDatePicker";
import Input from "../../../../../Components/Form/Input";
import Flex from "../../../../../Components/Flex";
import Button from "../../../../../Components/Form/Button";
import { Row } from "../../../../../Components/Row";
import { useState } from "react";
import Radio from "../../../../../Components/Form/RadioButton";
import dayjs from 'dayjs';
import { CustomInputNumber } from "../../../../../Components/Form/CustomInputNumber";
import request from "../../../../../utils/request";
import { useEffect } from "react";
import { toast } from "react-toastify";

export const ReceiptForm = ({ data, handleGetTable }) => {
    const [form] = Form.useForm();
    const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [value, setValue] = useState(null);
    const [receiptNumber, setReceiptNumber] = useState(null);

    useEffect(() => {
        GetReceiptNumber();
    }, [])

    const GetReceiptNumber = () => {
        request.get('receipts/last')
            .then(function (response) {
                setReceiptNumber(response.data.receiptno)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        if (data) {
            form.setFieldsValue({ receiptNo: data?.receiptNo })
        }
        else {
            form.setFieldsValue({ receiptNo: `${receiptNumber}` })
        }
    }, [receiptNumber])

    const handleOnChange = (date) => {
        setSelectedDate(date);
    };
    const onChange = (e) => {
        setValue(e.target.value);
    };

    const onFinish = (values) => {
        const NewValue = { ...values, date: selectedDate }
        if (data) {
            UpdateReceipt(NewValue)
        }
        else {
            ReceiptPost(NewValue);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const ReceiptPost = (values) => {
        request.post('receipts/save', values)
            .then(function (response) {

                if (response.status == 200) {
                    toast.success('Successfully Saved ')
                    form.resetFields();
                    GetReceiptNumber();
                }
                else {
                    console.log('Something went Wrong');
                }
            })
            .catch(function (error) {
                console.log(error, 'failedddd');
            });
    }

    const onReset = () => {
        form.resetFields();
        form.setFieldsValue({ receiptNo: `${receiptNumber}` })
        if (handleGetTable) {
            form.resetFields();
            handleGetTable();
        }
    };

    const RadioData = [
        {
            label: 'Cash',
            value: 'cash',
        },
        {
            label: 'Cheque',
            value: 'cheque',
        },
        {
            label: 'DD',
            value: 'dd',
        },
    ]

    //==========================edit=======================//
    const UpdateReceipt = (values) => {

        request.put(`receipts/edit/${data.receipt_id}`, values)
            .then(function (response) {
                handleGetTable();
                if (response.status == 200) {
                    toast.info('Successfully Updated Receipt details')
                    form.resetFields();
                }
                else {
                    console.log('Distributes details failed');
                }
            })
            .catch(function (error) {
                console.log(error, 'failedddd');
            });
    }

    useEffect(() => {
        form.setFieldsValue(data)
        if (data?.paymentType === 'cash') {
            setValue('cash')
        }
    }, [data])

    useEffect(() => {
        if (value === 'cash') {
            form.setFieldsValue({ chequeNo: null })
        }
    }, [value])

    return (
        <Form
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            initialValues={
                {
                    date: dayjs(),
                }
            }
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Row gutter={[24, 24]}>
                <Col span={24} md={12}>
                    <Input label={'Receipt No'} name={'receiptNo'} disabled />
                </Col>

                <Col span={24} md={12}>
                    {
                        data ? (
                            <Input label={'Date'} disabled name={'date'} />
                        ) : (
                            <CustomDatePicker label={'Date'} name={'date'} onChange={handleOnChange} rules={[
                                {
                                    required: true,
                                    message: 'Please Enter The date!',
                                }
                            ]} />

                        )
                    }
                </Col>

                <Col span={24} md={12}>
                    <Input label={'Received with thanks from '} name={'received'} placeholder={"Received from"} rules={[
                        {
                            required: true,
                            message: "Please Enter the person's name amount received from!",
                        }
                    ]} />
                </Col>

                <Col span={24} md={12}>
                    <Input label={'Towards'} placeholder={'towards'} name={'towards'} rules={[
                        {
                            required: true,
                            message: 'Please Enter for what the amount is paid!',
                        }
                    ]} />
                </Col>

                <Col span={24} md={12}>
                    <p>Payment Type :</p>
                    <div>
                        <Flex>
                            <Radio label={'Cash via'} name={'paymentType'} value={value} onChange={onChange} data={RadioData} rules={[
                                {
                                    required: true,
                                    message: 'Please Enter the Payment Type !',
                                }
                            ]} />
                        </Flex>
                    </div>
                </Col>
                {
                    data ? (
                        <Col span={24} md={12}>

                            {(value === 'cash') ?
                                <Input label={'Cheque/DD no'} name={'chequeNo'} disabled={true} />
                                :
                                <Input label={'Cheque/DD no'} name={'chequeNo'} placeholder={"Enter Cheque or DD number"} rules={[
                                    {
                                        required: true,
                                        message: 'Please enter Cheque/DD number!'
                                    }
                                ]} />

                            }
                        </Col>
                    ) : (
                        <Col span={24} md={12}>

                            {(value === 'cash') ?
                                <Input label={'Cheque/DD no'} name={'chequeNo'} disabled={true} />
                                :
                                <Input label={'Cheque/DD no'} name={'chequeNo'} placeholder={"Enter Cheque or DD number"} rules={[
                                    {
                                        required: true,
                                        message: 'Please enter Cheque/DD number!'
                                    }
                                ]} />

                            }
                        </Col>
                    )
                }

                <Col span={24} md={12}>
                    <Input label={'Bank'} name={'bank'} placeholder={"Enter from which bank "} />
                </Col>

                <Col span={24} md={12}>
                    <CustomInputNumber label={'Amount'} min={1.00} precision={2} name={'amount'} placeholder={'Amount'} rules={[
                        {
                            required: true,
                            message: 'Please Enter the amount !',
                        }
                    ]} />
                </Col>

            </Row>
            <Flex center gap={'20px'} style={{ margin: '20px 0px' }}>
                <Button.Primary text={'SUBMIT'} htmlType={'submit'} />
                <Button.Danger text={'CANCEL'} onClick={() => onReset()} />
            </Flex>
        </Form>
    )
}
