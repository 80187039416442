import React from 'react'
import ViewTable from './Partials/ViewTable'


const ViewPurchase = () => {
  return (
    <div>
      <ViewTable />
    </div>
  )
}

export default ViewPurchase