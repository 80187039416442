import React, { useEffect, useState } from 'react'
import { Table } from '../../../../../Components/Table'
import request from '../../../../../utils/request'
import { DeleteOutlined, EditOutlined, EyeOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import Button from '../../../../../Components/Form/Button'
import { Col, Popconfirm } from 'antd'
import Flex from '../../../../../Components/Flex'
import { Row } from '../../../../../Components/Row'
import { Modal } from '../../../../../Components/Modal'
import { AddBankDetails } from '../../AddBank/Partials/AddBankDetails'
import { toast } from 'react-toastify'

export const ViewBankDetails = () => {

  const [tableData, setTableData] = useState([])

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [modalTitle, setModalTitle] = useState("");

  const [modalContent, setModalContent] = useState(null);

  const [trigger, setTrigger] = useState(1)

  useEffect(() => {
    getBankDetails()
  }, [])

  const getBankDetails = () => {
    request.get('show/allbank')
      .then(response => {
        setTableData(response.data)
        console.log(response.data, 'qqqqqqqqq');
      })
      .catch(error => console.log(error, 'error'))
  }

  const onViewDetails = (record) => {
    setModalContent(<ViewBankModal record={record} />)
    setModalTitle("View Details");
    showModal();
  }


  const onEditDetails = (record) => {
    setTrigger(trigger + 1)
    setModalContent(<AddBankDetails record={record} trigger={trigger} HandleUpdateBank={HandleUpdateBank}/>)
    setModalTitle("Edit Details");
    showModal();
  }

  const ViewBankModal = ({ record }) => {
    return (
      <Row gutter={[24, 24]}>
        <Col span={24} md={12}>
          <h2>Bank Name&nbsp;:</h2>
        </Col>
        <Col span={24} md={12}>
          <h1>{record.bankName}</h1>
        </Col>
        <Col span={24} md={12}>
          <h2>Branch&nbsp;:</h2>
        </Col>
        <Col span={24} md={12}>
          <h1>{record.branchName}</h1>
        </Col>
        <Col span={24} md={12}>
          <h2>IFSC Code&nbsp;:</h2>
        </Col>
        <Col span={24} md={12}>
          <h1>{record.ifscCode}</h1>
        </Col>
        <Col span={24} md={12}>
          <h2>Account Number&nbsp;:</h2>
        </Col>
        <Col span={24} md={12}>
          <h1>{record.accountNumber}</h1>
        </Col>
        <Col span={24} md={12}>
          <h2>Date&nbsp;:</h2>
        </Col>
        <Col span={24} md={12}>
          <h1>{record.date}</h1>
        </Col>
        <Col span={24} md={12}>
          <h2>Payment Type&nbsp;:</h2>
        </Col>
        <Col span={24} md={12}>
          <h1>{record.paymentType}</h1>
        </Col>
        <Col span={24} md={12}>
          <h2>Amount&nbsp;:</h2>
        </Col>
        <Col span={24} md={12}>
          <h1>{record.amount}</h1>
        </Col>
        <Col span={24} md={12}>
          <h2>Phone Number&nbsp;:</h2>
        </Col>
        <Col span={24} md={12}>
          <h1>{record.phoneNumber}</h1>
        </Col>
      </Row>
    )
  }

  const HandleUpdateBank = () => {
    getBankDetails();
    handleOk();
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const confirm = (e) => {
    request.delete(`bank/delete/${e.bankId}`)
      .then(resp => {
        toast.success('Bank Details Deleted Successfully !')
        getBankDetails()
      })
      .catch(error => console.log(error, 'error'))
  };

  const cancel = (e) => {
    console.log(e);
  };

  const columns = [
    {
      title: 'SI No',
      render: (value, item, index) => index + 1,
    },
    {
      title: 'Bank Name',
      dataIndex: 'bank_name'
    },
    {
      title: 'Account Number',
      dataIndex: 'account_number'
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number'
    },
    {
      title: 'Amount',
      children: [
        {
          title: 'Credit',
          dataIndex: 'credit',
        },
        {
          title: 'Debit',
          dataIndex: 'debit',
        },
      ]
    },
    {
      title: 'Action',
      render: (record) => {
        return (
          <Flex center gap={'10px'}>
            <Button.Success text={<EyeOutlined />} onClick={() => onViewDetails(record)} />
            <Button.Primary text={<EditOutlined />} onClick={() => onEditDetails(record)} />
            <Popconfirm
              title="Delete Member"
              description="Are you sure to delete the member?"
              onConfirm={() => confirm(record)}
              onCancel={cancel}
              icon={
                <QuestionCircleOutlined size={'30'}
                  style={{
                    color: 'red',
                  }}
                />
              }
              placement="topLeft"
              okText="Yes"
              cancelText="No"
            >
              <Button.Danger text={<DeleteOutlined />} />
            </Popconfirm>

          </Flex>
        );
      },
    },


  ]
  return (
    <div style={{ margin: '30px 0' }}>
      <Table columns={columns} data={tableData} />
      <Modal width={800} isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} modalTitle={modalTitle} modalContent={modalContent} />
    </div>
  )
}
