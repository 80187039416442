import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Form, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import Flex from '../../../../../Components/Flex';
import Button from '../../../../../Components/Form/Button';
import Checkbox from '../../../../../Components/Form/Checkbox';
import { CustomDatePicker } from '../../../../../Components/Form/CustomDatePicker';
import { Table } from '../../../../../Components/Table';
import Label from '../../../../../Components/Form/Label';
import request from '../../../../../utils/request';
import Input from '../../../../../Components/Form/Input';
import Radio from '../../../../../Components/Form/RadioButton';


const Attendancetable = () => {

  const [dataSource, setDataSource] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [value, setValue] = useState(null);

  const [form] = Form.useForm();

  const handleActivate = (record) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(record.distributorid)) {
        return prevSelectedRows.filter((key) => key !== record.distributorid);
      } else {
        return [...prevSelectedRows, record.distributorid];
      }
    });
  }


  useEffect(() => {
    getMemberList()
  }, [])

  const getMemberList = () => {
    request.get('Distribution',)
      .then(function (response) {
        setDataSource(response.data)
      })

      .catch(function (error) {
        console.log(error);
      });
  }



  const RadioData = [
    {
      label: 'Half',
      value: 'Half',
    },
    {
      label: 'Full',
      value: 'Full',
    },
  ]

  useEffect(() => {
    dataSource.forEach(record => {
      form.setFieldsValue({ [`distributorid${record.distributorid}`]: record.distributorid });
      form.setFieldsValue({ [`att_status${record.distributorid}`]: record.att_status });
      form.setFieldsValue({ [`section${record.distributorid}`]: record.section });
    });
  }, [dataSource])


  const SectionChange = (e) => {
    setValue(e.target.value);
  };

  const columns = [
    {
      title: 'Mem Id',
      dataIndex: 'distributorid',
      render: (text, record) => {
        return (
          <>
            <span>{text}</span>
            <Input name={`distributorid${record.distributorid}`} />
          </>
        )
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',

    },
    {
      title: 'Designation',
      dataIndex: 'designation',
    },
    {
      title: 'Status',
      render: (_, record) => {
        const isRowSelected = selectedRows.includes(record.distributorid);
        return (
          <>
            {isRowSelected ? <h3 style={{ color: 'Green' }}> Present</h3> : <h3 style={{ color: 'Red' }}>Absent</h3>}
          </>
        )
      }
    },
    {
      title: 'Attendance',
      children: [
        {
          title: 'Present',
          render: (_, record) => {
            const isRowSelected = selectedRows.includes(record.distributorid);
            return (
              <Checkbox
                name={`att_status${record.distributorid}`}
                checked={isRowSelected}
                onChange={() => handleActivate(record)}
              />
            );
          }
        },
        {
          title: 'Section',
          render: (_, record) => {
            const isRowSelected = selectedRows.includes(record.distributorid);

            return (
              <Radio name={`section${record.distributorid}`} label={'Gender'} disabled={!isRowSelected} value={value} onChange={() => SectionChange(record)} data={RadioData} />
            )
          }
        },
      ]
    },
  ]

  const handleDateChange = () => {

  }

  const onFinish = (values) => {
    console.log(values, 'DATA TO BE POSTED');
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  return (
    <>
      <Flex spcPading flexEnd centerVertically>
        <Label>Date :&nbsp;&nbsp;</Label>
        <CustomDatePicker width={200} onChange={handleDateChange} />
      </Flex><br />
      <Form
        form={form}
        name="attendance"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">

        <Table columns={columns} data={dataSource} />

        <Flex spaceEvenly style={{ margin: '20px' }} gap={'20px'}>
          <Button.Primary htmlType="submit" text={'Save'} />
        </Flex>
      </Form>
    </>
  )
}

export default Attendancetable
