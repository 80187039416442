import React from 'react'
import { DayBookReportTable } from './Partials/DayBookReportTable'

export const DayBookreport = () => {
    return (
        <div>
            <DayBookReportTable />
        </div>
    )
}
