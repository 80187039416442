import React, { useEffect, useState } from 'react'
import { Form, Col, } from 'antd'
import { Row } from '../../../../../Components/Row'
import Input from '../../../../../Components/Form/Input'
import Flex from '../../../../../Components/Flex'
import Button from '../../../../../Components/Form/Button'
import { CustomInputNumber } from '../../../../../Components/Form/CustomInputNumber'
import { CustomDatePicker } from '../../../../../Components/Form/CustomDatePicker'
import dayjs from 'dayjs'
import { Select } from '../../../../../Components/Form/Select'
import request from '../../../../../utils/request'
import { toast } from 'react-toastify'

export const Addsalary = ({ record }) => {

    const [form] = Form.useForm()

    const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'))

    const [salary, setSalary] = useState(null)

    const [member, setMember] = useState([])

    const [memberId, setmemberId] = useState(null)

    const [selectedMember, setselectedMember] = useState()

    useEffect(() => {
        getMember()
    }, [])

    useEffect(() => {
        form.setFieldsValue({
            memberid: memberId,
            role_name: selectedMember?.role_name,
            phone: selectedMember?.phone
        })
    }, [memberId])

    const options = [
        {
            label: 'Advance',
            value: 'advanceAmount',
        },
        {
            label: 'Full Payment',
            value: 'fullPayment',
        },
    ]

    const getMember = () => {
        request.get('rolelist')
            .then(response => {
                setMember(response.data)
                console.log(response.data, 'rolelist');
            })
            .catch(error => console.log(error, 'errorr'))
    }

    const nameOptions = member?.map(val => ({
        label: val.name,
        value: val.name
    }))

    const handleNameOptions = (e) => {
        const selectedMem = member?.find((item) => item.name === e)
        setmemberId(selectedMem.memberid)
        setselectedMember(selectedMem)
    }

    const handleOptions = (e) => {
        setSalary(e)
    }

    const handleAmountChange = (e) => {
        if (e <= 0) {
            form.resetFields(['amount'])
        }
    }

    const onFinish = (value) => {
        console.log(value, 'result');
        form.resetFields()
        postData(value)
    }

    const postData = (value) => {
        request.post('salary/save', value)
            .then(response => {
                toast.success('Salary Added Successfully !')
                setSalary(null)
                setmemberId(null)
                setselectedMember()
            })
            .catch(error => console.log(error, 'error'))
    }

    const onFinishFailed = (value) => {
        console.log(value);

    }
    const dateOnChange = (ddd) => {
        setSelectedDate(ddd)
    }

    const onReset = () => {
        form.resetFields()
        setSalary(null)
    }

    return (
        <Form
            form={form}
            name="basic"
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            initialValues={{
                date: dayjs(),
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">

            <Row gutter={[24, 24]}>
                <Col span={24} md={12}>
                    <Select options={nameOptions} onChange={handleNameOptions} label={'Name'} placeholder={'Enter name'} name={'name'} rules={[
                        {
                            required: true,
                            message: 'This field is required !'
                        }
                    ]} />
                    <Input name={'memberid'} display={'none'} />
                </Col>
                <Col span={24} md={12}>
                    <Input label={'Role'} disabled={true} name={'role_name'} />
                </Col>
                <Col span={24} md={12}>
                    <Input label={'Phone Number'} disabled={true} name={'phone'} />
                </Col>
                <Col span={24} md={12}>
                    <CustomDatePicker label={'Date'} onChange={dateOnChange} name={'date'} rules={[
                        {
                            required: true,
                            message: 'This field is required !'
                        }
                    ]} />
                </Col>
                <Col span={24} md={12}>
                    <Select onChange={handleOptions} options={options} label={'Salary'} placeholder={'Select salary type'} name={'paymentType'} rules={[
                        {
                            required: true,
                            message: 'This field is required !'
                        }
                    ]} />
                </Col>
                <Col span={24} md={12}>
                    {(salary === 'fullPayment') ?
                        <CustomInputNumber label={'Advance Amount'} name={'advanceAmount'} disabled />
                        : null}
                </Col>
                <Col span={24} md={12}>
                    <CustomInputNumber onChange={handleAmountChange} label={'Amount'} placeholder={'Enter amount'} name={'amount'} rules={[
                        {
                            required: true,
                            message: 'This field is required !'
                        }
                    ]} />
                </Col>
                <Col span={24} md={12}>
                </Col>
                <Flex center W_100 gap={'20px'}>
                    <Button.Primary text={'Save'} htmlType={'submit'} />
                    <Button.Danger text={'Clear'} onClick={onReset} />
                </Flex>
            </Row>
        </Form>
    )
}
