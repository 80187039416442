import React, { useEffect, useState } from 'react'
import request from '../../../../../utils/request'
import { Table } from '../../../../../Components/Table'

export const ViewTableModal = ({ record }) => {

    const [tableData, setTableData] = useState([])

    useEffect(() => {
        tableModal()
    }, [record])

    const filteredData = tableData.filter(obj => obj.amount !== null && obj.payment_date !== null && obj.amount !== 0);

    const columns = [
        {
            title: 'SI.No',
            render: (value, item, index) =>  index + 1 ,
        },
        {
            title: 'Received Amount',
            dataIndex: 'amount'
        },
        {
            title: 'Pay Date',
            dataIndex: 'payment_date'
        }

    ]
    
    const tableModal = () => {
        request.get(`total/sales/${record?.sales_id}`)
            .then(response => {
                setTableData(response.data.report)
            })
            .catch(error => console.log(error, 'error'))
    }


    return (
        <Table columns={columns} data={filteredData} />
    )
}
