import React from 'react'
import { TopTitle } from '../../../../../Components/Form/TopTitle';
import { Tabs } from '../../../../../Components/Tabs';
import { ChineseFormat } from './ChineseFormat';
import { BillDetails } from './BillDetails';
import { LocalFormats } from './LocalFormats';

const ChooseBills = ({record}) => {

    console.log(record);

    const items = [
        {
          key: '1',
          label: 'Local Format',
          children: <LocalFormats record={record}/>,
        },
        {
          key: '2',
          label: 'Chinese Format',          
          children: <ChineseFormat record={record}/>,
        },
        // {
        //   key: '3',
        //   label: 'Bill Details',          
        //   children: <BillDetails record={record}/>,
        // },
      ];

  return (
    <div style={{ margin: '30px 0' }}>
        <TopTitle Heading={'Choose Bills'} />

        <Tabs items={items}/>
        </div>
  )
}

export default ChooseBills