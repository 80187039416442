import React from 'react'
import { Cardsin, BillTable, PrintTitle, PrintSubTitle } from './style'
import { Col, } from 'antd'
import { Row } from '../../../../../Components/Row'
import { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import Button from '../../../../../Components/Form/Button'
import { Fragment } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import request from '../../../../../utils/request'
import * as numToWord from 'num-to-text';
import Flex from '../../../../../Components/Flex'
import { InvoiceTitle } from '../../../../../Components/Form/FormTitle'
import dayjs from 'dayjs'
import signature from '../../../../../Images/Signaturee.png'
// import signature from '../../../../../Images/Signature-removebg-preview.png'


export const ChineseFormat = ({ record }) => {

    const [companyProfile, setCompanyProfile] = useState({})

    useEffect(() => {
        GetCompany();
    }, [])

    console.log(companyProfile, 'lllll');

    const GetCompany = () => {
        request.get('mycompany')
            .then(function (response) {
                setCompanyProfile(response.data[0])
            })
            .catch(function (error) {
                console.log(error, 'failedddd');
            });
    }

    const NumConvert = numToWord(record?.roundoff_amount, 'in');
    function convertNumberToYuanWords(number) {
        // Replace this function with a more complete implementation if needed
        const units = ['', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
        const decimals = ['角', '分'];
        const yuan = '元';
      
        const parts = String(number).split('.');
        let yuanPart = '';
        let decimalPart = '';
      
        if (parts[0]) {
          yuanPart = parts[0]
            .split('')
            .reverse()
            .map((digit, index, array) => {
              const unit = units[digit];
              const unitName = unit !== '一' && index === 1 ? '十' : unit;
              return unitName;
            })
            .reverse()
            .join('');
        }
      
        if (parts[1]) {
          decimalPart = parts[1]
            .split('')
            .map((digit, index) => {
              return units[digit] + decimals[index];
            })
            .join('');
        }
      
        const result = yuanPart + yuan + decimalPart;
        return result;
      }
      
      const numberInYuanWords = convertNumberToYuanWords(record?.roundoff_amount);
      console.log(numberInYuanWords);
      

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1; // Adding 1 because months are zero-based
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }

    const formattedDate = formatDate(record?.purchase_date);

    const PrintedDate = dayjs(record?.purchase_date).format('DD-MMM-YY');
    const HeaderTable = () => {

        return (
            <Cardsin>
                <Row>
                    <Col span={24} md={12} style={{ borderRight: '1px solid', padding: '2px 10px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <PrintTitle Size={'16px'} Weight={'600'}>Seller :</PrintTitle>
                                </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <PrintTitle Size={'16px'} UPPER Weight={'600'}> {companyProfile.companyname}</PrintTitle>
                            <PrintTitle Size={'14px'} UPPER Weight={'600'} TextAlign={'left'}>{companyProfile.address}, {companyProfile.state}-{companyProfile.pincode}, {companyProfile.district} DISTRICT.</PrintTitle>
                            <PrintTitle Size={'14px'} UPPER Weight={'600'}>GSTIN / UIN&nbsp;:&nbsp;<span style={{ textTransform: 'uppercase' }}>{companyProfile.gstno}</span></PrintTitle>
                            <PrintTitle Size={'14px'} Weight={'600'}>State&nbsp;Name&nbsp;:&nbsp;<span style={{ textTransform: 'capitalize' }}>{companyProfile.state}</span>, Code: {companyProfile.code}</PrintTitle>
                            <PrintTitle Size={'14px'} Weight={'600'}>E-Mail&nbsp;:&nbsp;{companyProfile.email}</PrintTitle>
                        </div>

                    </Col>
                    <Col span={24} md={12} >
                        <Row>
                            <Col span={24} md={12} style={{ padding: '5px', textAlign: 'center', borderBottom: '1px solid', borderRight: '1px solid' }}>
                                <PrintTitle Size={'14px'} Weight={'600'}>Invoice No.</PrintTitle>
                                <PrintTitle Size={'14px'} UPPER Weight={'600'}>{record?.invoice_no}</PrintTitle>
                            </Col>
                            <Col span={24} md={12} style={{ padding: '5px', textAlign: 'center', borderBottom: '1px solid' }}>
                                <PrintTitle Size={'14px'} Weight={'600'}>Invoice Date</PrintTitle>
                                <PrintTitle Size={'14px'} UPPER Weight={'600'}>{formattedDate}</PrintTitle>
                            </Col>
                            <Col span={24} md={24} style={{ padding: '2px 10px' }}>
                                
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <PrintTitle Size={'16px'} Weight={'600'}>Buyer :</PrintTitle>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <PrintTitle Size={'16px'} UPPER Weight={'600'}>{record?.companyname}</PrintTitle>
                                    <PrintTitle Size={'14px'} UPPER Weight={'600'} >{record?.address}</PrintTitle>
                                    {/* <PrintTitle Size={'14px'} UPPER Weight={'600'}>GSTIN / UIN&nbsp;:&nbsp;{record?.gstin}</PrintTitle> */}
                                    {/* <PrintTitle Size={'14px'} Weight={'600'}>State&nbsp;Name&nbsp;:&nbsp;{record?.state},</PrintTitle> */}
                                    {/* <PrintTitle Size={'14px'} Weight={'600'}>Contact&nbsp;:&nbsp;{record?.contact}</PrintTitle> */}
                                    {/* <PrintTitle Size={'14px'} Weight={'600'}>Invoice Number&nbsp;:&nbsp;{record?.invoice_no}</PrintTitle> */}
                                    {/* <PrintTitle Size={'14px'} Weight={'600'}>E-Mail&nbsp;:&nbsp;{record?.email}</PrintTitle> */}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Cardsin>
        )
    }

    const FooterComponent = () => {

        const formattedRoundOff = record?.total_price.toLocaleString('zh-CN', { minimumFractionDigits: 2 });
        const formattedRoundOffPay = record?.roundoff.toLocaleString('zh-CN', { minimumFractionDigits: 2 });
        const formattedTotal = record?.total_price.toLocaleString('zh-CN', { minimumFractionDigits: 2 });

        return (
            <div style={{ background: 'var(--light-color)', padding: '10px' }}>
<br/>
                <Row >
                    <Col span={24} md={10} style={{marginBottom:'20px'}}>
                        {/* <PrintTitle Size={'12px'} TextAlign={'left'}>Amount Chargeable (in words)</PrintTitle>
                        <PrintTitle Size={'12px'} Weight={'600'} TextAlign={'left'}>{numberInYuanWords} Only</PrintTitle> */}
                    </Col>
                    <Col span={24} md={14}>
                        <hr />
                        <Row gutter={[12, 12]} style={{ justifyContent: 'end', display: 'flex', textAlign: 'end' }}>

                            <Col span={24} md={14}>
                                <div>
                                    <PrintSubTitle Size={'14px'} Weight={'600'}>Total:</PrintSubTitle><br />

                                    {/* {record?.igst && <><PrintSubTitle Size={'14px'} Weight={'600'}>IGST&nbsp;:</PrintSubTitle><br /></>}
                                    {record?.cgst && <><PrintSubTitle Size={'14px'} Weight={'600'}>GST&nbsp;@{record?.tax_percentage}%&nbsp;:</PrintSubTitle><br /></>}
                                    {/* {record?.sgst && <><PrintSubTitle Size={'14px'} Weight={'600'}>SGST&nbsp;@9%&nbsp;:</PrintSubTitle><br /></>} 
                                    <PrintSubTitle Size={'14px'} Weight={'600'}>Less&nbsp;:&nbsp;Round Off&nbsp;:</PrintSubTitle><br /> */}
                                </div>
                            </Col>
                            <Col span={24} md={10}>
                                <div>
                                    <PrintSubTitle Size={'14px'} Weight={'600'}>&yen; {formattedRoundOff}</PrintSubTitle><br />

                                    {/* {record?.igst && <><PrintSubTitle Size={'14px'} Weight={'600'}>{record?.igst.toLocaleString('zh-CN', { minimumFractionDigits: 2 })}</PrintSubTitle><br /></>}
                                    {record?.cgst && <><PrintSubTitle Size={'14px'} Weight={'600'}>{record?.total_tax.toLocaleString('zh-CN', { minimumFractionDigits: 2 })}</PrintSubTitle><br /></>}
                                    {/* {record?.sgst && <><PrintSubTitle Size={'14px'} Weight={'600'}>{record?.sgst.toLocaleString('zh-CN', { minimumFractionDigits: 2 })}</PrintSubTitle><br /></>} 
                                    <PrintSubTitle Size={'14px'} Weight={'600'}>(-){formattedRoundOffPay}</PrintSubTitle> */}
                                </div>
                            </Col>
                        </Row><br />
                        {/* <hr /> */}
                        {/* <br /> */}
                        {/* <Row gutter={[12, 12]} style={{ justifyContent: 'end', display: 'flex', textAlign: 'end', }}>
                            <Col span={24} md={12}><PrintTitle Size={'14px'} Weight={'600'}>Grand&nbsp;Total&nbsp;:&nbsp;</PrintTitle></Col>
                            <Col span={24} md={12}><PrintTitle Size={'14px'} Weight={'600'}>{formattedRoundOff}</PrintTitle></Col>
                        </Row> */}
                    </Col>
                </Row><br />
            </div >
        )
    }

    // const FooterComponent = () => {

    //     const formattedTotalAmount = record?.roundoff_amount.toLocaleString('zh-CN', { minimumFractionDigits: 2 });
    //     const formattedroundOff = record?.roundoff.toLocaleString('zh-CN', { minimumFractionDigits: 2 });
    //     const formattedTotalPrice = record?.total_price.toLocaleString('zh-CN', { minimumFractionDigits: 2 });
    //     return (
    //         <div style={{ background: 'var(--light-color)', pageBreakInside: 'avoid', padding: '10px' }}>
    //             <Row>
    //                 <Col span={24} md={10}>
    //                     <PrintTitle Size={'12px'} TextAlign={'left'}>Amount Chargeable (in words)</PrintTitle>
    //                     <PrintTitle Size={'12px'} Weight={'600'} TextAlign={'left'}>INR {NumConvert} Only</PrintTitle>
    //                 </Col>
    //                 <Col span={24} md={14}>
    //                     <Row gutter={[12, 12]} style={{ justifyContent: 'end', display: 'flex', textAlign: 'end' }}>

    //                         <Col span={24} md={14}>
    //                             <div>
    //                                 {
    //                                     record?.taxlist.map((tax) => {
    //                                         console.log(tax, 'tax.sgst')
    //                                         return (
    //                                             tax.gst_type ? (
    //                                                 <><PrintSubTitle Size={'14px'} Weight={'600'}>CGST @{tax.cgst_tax}%&nbsp;:&nbsp;</PrintSubTitle> <br />
    //                                                     <PrintSubTitle Size={'14px'} Weight={'600'}>SGST @{tax.sgst_tax}%&nbsp;:&nbsp;</PrintSubTitle> <br /></>
    //                                             ) :
    //                                                 (<><PrintSubTitle Size={'14px'} Weight={'600'}>IGST @{tax.igst_tax}%&nbsp;:&nbsp;</PrintSubTitle> <br /></>)
    //                                         )
    //                                     })
    //                                 }

    //                                 <PrintSubTitle Size={'14px'} Weight={'600'}>Total&nbsp;:&nbsp;</PrintSubTitle><br />
    //                                 <PrintSubTitle Size={'14px'} Weight={'600'}>Less&nbsp;:&nbsp;&nbsp;Round Off&nbsp;:&nbsp;</PrintSubTitle><br /></div>
    //                         </Col>
    //                         <Col span={24} md={10}>
    //                             <div>
    //                                 {
    //                                     record?.taxlist.map((tax) => {
    //                                         return (
    //                                             tax.gst_type ? (
    //                                                 <><PrintSubTitle Size={'14px'} Weight={'600'}>{tax.cgst.toLocaleString('zh-CN', { minimumFractionDigits: 2 })}</PrintSubTitle> <br />
    //                                                     <PrintSubTitle Size={'14px'} Weight={'600'}>{tax.sgst.toLocaleString('zh-CN', { minimumFractionDigits: 2 })}</PrintSubTitle> <br /></>
    //                                             ) :
    //                                                 (<><PrintSubTitle Size={'14px'} Weight={'600'}>{tax.igst.toLocaleString('zh-CN', { minimumFractionDigits: 2 })}</PrintSubTitle> <br /></>)
    //                                         )
    //                                     })
    //                                 }

    //                                 <PrintSubTitle Size={'14px'} Weight={'600'}>{formattedTotalPrice}</PrintSubTitle> <br />
    //                                 <PrintSubTitle Size={'14px'} Weight={'600'} >(-){formattedroundOff}</PrintSubTitle>
    //                             </div>
    //                         </Col>
    //                     </Row><br />
    //                     <hr />
    //                     <br />
    //                     <Row gutter={[12, 12]} style={{ justifyContent: 'end', display: 'flex', textAlign: 'end', alignItems: 'center' }}>
    //                         <Col span={24} md={12}><PrintTitle Size={'14px'} Weight={'600'}>Grand&nbsp;Total&nbsp;:&nbsp;</PrintTitle></Col>
    //                         <Col span={24} md={12}><PrintTitle Size={'14px'} Weight={'600'}>{formattedTotalAmount}</PrintTitle></Col>
    //                     </Row>
    //                 </Col>
    //             </Row><br />
    //         </div >
    //     )
    // }

    const BottomFooterComponent = () => {
        return (
            <div style={{ pageBreakInside: 'avoid' }}>
                <Row gutter={[12, 12]}>
                    <Col span={24} md={10}><PrintSubTitle Under Size={'12px'} Weight={'600'}>Terms and Conditions</PrintSubTitle>
                        {/* <PrintTitle Size={'10px'} >We declare that this invoice shows the actual price of the <br />
                            goods described and that all particulars are true and correct</PrintTitle> */}
                            <PrintTitle Size={'10px'} >please send payment within 30 days of <br />
                            receiving this invoice. There will be 10% interest <br/>charge per month on late invoice</PrintTitle>
                    </Col>
                    <Col span={24} md={14}>
                        <PrintTitle Size={'12px'} >Company's Bank Details</PrintTitle>
                        <Flex gap={'5px'}>
                            <div style={{ textAlign: 'start' }}>
                                <div style={{ textAlign: 'start' }}>
                                    <PrintTitle Size={'12px'} >Account Holder Name&nbsp;</PrintTitle>
                                </div>
                                <div style={{ textAlign: 'start' }}>
                                    <PrintTitle Size={'12px'} >Bank Name&nbsp;</PrintTitle>
                                </div>
                                <div style={{ textAlign: 'start' }}>
                                    <PrintTitle Size={'12px'} >A/c No.&nbsp;</PrintTitle>
                                </div>
                                <div style={{ textAlign: 'start' }}>
                                    <PrintSubTitle Size={'12px'} >Branch&nbsp;</PrintSubTitle>&nbsp;&&nbsp;
                                    <PrintSubTitle Size={'12px'} >IFSC&nbsp;code&nbsp;</PrintSubTitle>
                                </div>
                                <div style={{ textAlign: 'start' }}>
                                </div>
                            </div>
                            <div style={{ textAlign: 'start' }}>
                                <div style={{ textAlign: 'start' }}>
                                    <PrintTitle Size={'12px'} UPPER Weight={'600'} >:&nbsp;&nbsp;{companyProfile.companyname}</PrintTitle>
                                </div>
                                <div style={{ textAlign: 'start' }}>
                                    <PrintTitle Size={'12px'} UPPER Weight={'600'} >:&nbsp;&nbsp;{companyProfile.bankname}</PrintTitle>
                                </div>
                                <div style={{ textAlign: 'start' }}>
                                    <PrintTitle Size={'12px'} UPPER Weight={'600'} >:&nbsp;&nbsp;{companyProfile.account_no}</PrintTitle>
                                </div>
                                <div style={{ textAlign: 'start' }}>
                                    <PrintSubTitle Size={'12px'} UPPER Weight={'600'} >:&nbsp;&nbsp;{companyProfile.branch}&nbsp;&</PrintSubTitle>
                                    <PrintSubTitle Size={'12px'} UPPER Weight={'600'} >&nbsp;{companyProfile.ifsc_code}</PrintSubTitle>
                                </div>
                                <div style={{ textAlign: 'start' }}>
                                </div>
                            </div>
                        </Flex>
                    </Col>
                </Row>
                <div style={{ border: '1px solid', padding: '2px 10px', marginTop: '5px' }}>
                    <Row>
                        <Col span={12}><PrintSubTitle Size={'12px'} Weight={'600'}>Customer's Seal and Signature</PrintSubTitle></Col>
                        <Col span={12} style={{ textAlign: 'end' }}><b>for</b><PrintSubTitle Size={'14px'} Weight={'600'} UPPER> {companyProfile.companyname}</PrintSubTitle></Col>
                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px' }}>
                        <img src={signature} style={{ width: '150px' }} />
                    </div>
                    <PrintTitle Size={'14px'} TextAlign={'end'} >Authorised Signatory</PrintTitle>
                </div>
                <PrintTitle Size={'14px'} TextAlign={'center'}>This is a Computer Generated Invoice</PrintTitle>
            </div>
        )
    }


    const rows = record?.purchaselist.map((item, index) => {
        console.log(item,'lllll');
        const formattedAmount = item.total_price.toLocaleString('zh-CN', { minimumFractionDigits: 2 });
        const formattedTaxPrice = item.inc_tax_price.toLocaleString('zh-CN', { minimumFractionDigits: 2 });
        const formattedPrice = item.price.toLocaleString('zh-CN', { minimumFractionDigits: 2 });
        const formattedValue = item.tax_qty_amount.toLocaleString('zh-CN', { minimumFractionDigits: 2 });
        return (
            <tr key={index}>
                {/* <td>{index + 1}</td> */}
                <td style={{ padding: '5px' }} colSpan="4"><span style={{ fontSize: '12px', fontWeight: '600', letterSpacing: '.5px' }}>{item.productname}</span></td>
                {/* <td style={{ padding: '5px' }} colSpan="3">{item.hsn_code}</td> */}
                {/* <td style={{ padding: '5px' }} colSpan="2">{item.shipped_qty} {item.unitname}</td> */}
                {/* <td style={{ padding: '5px' }} colSpan="2">{item.qty} {item.unitname} </td> */}
                {/* <td style={{ padding: '5px' }} colSpan="3">{formattedTaxPrice}</td> */}
                {/* <td style={{ padding: '5px' }} colSpan="3">{formattedPrice}</td> */}
                {/* <td style={{ padding: '5px' }} colSpan="3">{item.tax_percentage}&nbsp;%</td> */}
                {/* <td style={{ padding: '5px' }} colSpan="3">{item.unitname}</td> */}
                <td style={{ padding: '5px' }} colSpan="3">&yen; {formattedAmount}</td>
                <td style={{ padding: '5px' }} colSpan="3">&yen; {formattedAmount}</td>
            </tr>
        );
    });

    return (
        <Fragment>
            <Button.Primary onClick={handlePrint} text={'Click To Print'} />

            <BillTable ref={componentRef} >
               <div style={{marginTop:'50px'}}>
               <Row>
                    <Col span={14}>
                        <div style={{ textAlign: 'center' }}>
                            <InvoiceTitle Title={'TAX INVOICE'} />
                        </div>
                    </Col>
                    <Col span={10}>
                        <div style={{ textAlign: 'end', marginRight: '40px' }}>
                            <PrintTitle Size={'14px'}>Printed&nbsp;on&nbsp;{PrintedDate}</PrintTitle>
                            <PrintTitle Size={'16px'} UPPER >(Original for Receipt)</PrintTitle>
                        </div>
                    </Col>
                </Row>
               </div>
                <table>
                    <thead>
                        <tr>
                            <th colSpan={30}><HeaderTable /></th>
                        </tr>
                        <tr>
                            {/* <th style={{ fontSize: '14px', fontWeight: '500' }} rowSpan="2">Sl.&nbsp;No</th> */}
                            <th style={{ fontSize: '14px', fontWeight: '500' }} rowSpan="2" colSpan="4">Description</th>
                            {/* <th style={{ fontSize: '14px', fontWeight: '500' }} rowSpan="2" colSpan="3">HSN/SAC</th> */}
                            {/* <th style={{ fontSize: '14px', fontWeight: '500' }} colSpan="4">Quantity</th> */}
                            {/* <th style={{ fontSize: '14px', fontWeight: '500' }} rowSpan="2" colSpan="3">Rate (Incl. of Tax)</th> */}
                            {/* <th style={{ fontSize: '14px', fontWeight: '500' }} rowSpan="2" colSpan="3">Rate</th> */}
                            {/* <th style={{ fontSize: '14px', fontWeight: '500' }} rowSpan="2" colSpan="3">Tax %</th> */}
                            {/* <th style={{ fontSize: '14px', fontWeight: '500' }} rowSpan="2" colSpan="3">per</th> */}
                            <th style={{ fontSize: '14px', fontWeight: '500' }} rowSpan="2" colSpan="3">Price</th>
                            <th style={{ fontSize: '14px', fontWeight: '500' }} rowSpan="2" colSpan="3">Total</th>
                        </tr>
                        {/* <tr>
                            <th style={{ fontSize: '14px', fontWeight: '500' }} colSpan="2" >shipped</th>
                            <th style={{ fontSize: '14px', fontWeight: '500' }} colSpan="2" >Billed</th>
                        </tr> */}
                    </thead>
                    <tbody>
                        {
                            rows
                        }
                    </tbody>
                </table>
                <FooterComponent />
                <BottomFooterComponent />
            </BillTable>
        </Fragment>
    )
}
