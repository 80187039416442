import axios from 'axios'

const request = axios.create({
  // baseURL: 'http://localhost:8083/', // API server url
  // baseURL: 'http://192.168.29.106:8080/', // API server url
  baseURL: 'https://business.api.ideauxbill.in/', // API server url
  // baseURL: 'http://192.168.29.66:8083/', // API server url
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
})

export default request


