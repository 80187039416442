import React from 'react'
import Cardboard from './Partials/Cardboard'


export const Home = () => {
  return (
    <Cardboard/>
  )
}

