import React from 'react'
import { SalesReportTable } from './Partials/SalesReportTable'

export const SalesReport = () => {
  return (
    <div>
        <SalesReportTable/>
    </div>
  )
}
