import React, { Fragment } from 'react'
import { BankContainer } from './Partials/BankContainer'

export const BankIndex = () => {
  return (
    <Fragment>
        <BankContainer/>
    </Fragment>
  )
}
