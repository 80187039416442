import React from 'react'
import { PurchaseReportTable } from './Partials/PurchseReportTable'

export const PurchseReport = () => {
  return (
    <div>
        <PurchaseReportTable/>
    </div>
  )
}
