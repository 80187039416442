import React, { Fragment } from 'react'
import TopSection from './Partials/TopSection'
import { TopTitle } from '../../../../Components/Form/TopTitle'


export const CompanyReport = () => {
  return (
    <Fragment>
      <TopTitle Heading={'OutSource Company Report'} />

      <TopSection />
    </Fragment>
  )
}
