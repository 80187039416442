import { Col, Form } from "antd";
import Flex from "../../../../../Components/Flex";
import { Row } from "../../../../../Components/Row";
import { useEffect, useState } from "react";
import Input from "../../../../../Components/Form/Input";
import { InputNumber } from "../../../../../Components/Form/InputNumber";
import Button from "../../../../../Components/Form/Button";
import { CustomDatePicker } from "../../../../../Components/Form/CustomDatePicker";
import { TopTitle } from "../../../../../Components/Form/TopTitle";
import dayjs from 'dayjs';
import { CustomInputNumber } from "../../../../../Components/Form/CustomInputNumber";
import { TextAreas } from "../../../../../Components/Form/TextArea";
import Checkbox from "../../../../../Components/Form/Checkbox";
import Label from "../../../../../Components/Form/Label";
import request from "../../../../../utils/request";
import { toast } from "react-toastify";

export const VoucherForm = ({ data, handleGetTable }) => {
    const [form] = Form.useForm();
    const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [voucherNumber, setVoucherNumber] = useState(null);


    useEffect(() => {
        GetVoucherNumber();
    }, [])


    const GetVoucherNumber = () => {
        request.get('vouchers/last')
            .then(function (response) {
                setVoucherNumber(response.data.voucherno)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        if (data) {
            form.setFieldsValue({ voucherNo: data?.voucherNo })
        }
        else {
            form.setFieldsValue({ voucherNo: `${voucherNumber}` })
        }
    }, [voucherNumber])

    const handleOnChange = (date) => {
        setSelectedDate(date);
    };

    const onFinish = (values) => {
        const NewValue = { ...values, date: selectedDate }
        if (data) {
            UpdateVoucher(NewValue)
        }
        else {
            VoucherPost(NewValue);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const VoucherPost = (values) => {
        request.post('vouchers/save', values)
            .then(function (response) {

                if (response.status == 200) {
                    toast.success('Successfully Saved ')
                    form.resetFields();
                    GetVoucherNumber();
                }
                else {

                    console.log('Something went Wrong');
                }
            })
            .catch(function (error) {
                console.log(error, 'failedddd');
            });
    }

    const onReset = () => {
        form.resetFields();
        form.setFieldsValue({ voucherNo: `${voucherNumber}` })
        if (handleGetTable) {
            form.resetFields();
            handleGetTable();
        }
    };


    //==========================edit=======================//
    const UpdateVoucher = (values) => {

        request.put(`vouchers/edit/${data.voucher_id}`, values)
            .then(function (response) {
                handleGetTable();
                if (response.status == 200) {
                    toast.info('Successfully Updated Receipt details')
                    form.resetFields();
                }
                else {
                    console.log('Distributes details failed');
                }
            })
            .catch(function (error) {
                console.log(error, 'failedddd');
            });
    }
    
    useEffect(() => {
        form.setFieldsValue(data)
    }, [data])


    return (
        <Form
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            initialValues={
                {
                    date: dayjs(),
                    returnType: false,
                }
            }
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Row gutter={[24, 24]}>

                <Col span={24} md={12}>
                    <Input label={'Voucher No'} name={'voucherNo'} disabled />
                </Col>

                <Col span={24} md={12}>
                    {
                        data ? (
                            <Input label={'Date'} disabled name={'date'} />
                        ) : (
                            <CustomDatePicker label={'Date'} name={'date'} onChange={handleOnChange} rules={[
                                {
                                    required: true,
                                    message: 'Please Enter The date!',
                                }
                            ]} />
                        )
                    }
                </Col>

                <Col span={24} md={12}>
                    <Input label={'Paid to'} name={'paidTo'} placeholder={"Name"} rules={[
                        {
                            required: true,
                            message: "Please Enter the person's name you paid to!",
                        }
                    ]} />
                </Col>

                <Col span={24} md={12}>
                    <CustomInputNumber label={'Amount'} min={1.0} precision={2} name={'amount'} placeholder={'Amount'} rules={[
                        {
                            required: true,
                            message: 'Please Enter the amount !',
                        }
                    ]} />
                </Col>

                <Col span={24} md={12}>
                    <TextAreas label={'Particulars'} name={'particulars'} placeholder={"Write the particulars"} />
                </Col>

                <Col span={24} md={12}>
                    <Flex center H_100 column spaceEvenly>
                        <Label>Return Type</Label>
                        <Checkbox name={'returnType'} label={'Amount Return'} />
                    </Flex>
                </Col>

            </Row>
            <Flex center gap={'20px'} style={{ margin: '20px 0px' }}>
                <Button.Success text={'SUBMIT'} htmlType={'submit'} />
                <Button.Danger text={'CANCEL'} onClick={() => onReset()} />
            </Flex>
        </Form>
    )
}