import React from 'react'
import { ProductReportTable } from './Partials/ProductReportTable'

export const ProductReport = () => {
  return (
    <div>
      <ProductReportTable />
    </div>
  )
}
