import React from 'react'
import { TopTitle } from '../../../../Components/Form/TopTitle'
import { CustomCardView } from '../../../../Components/CustomCardView'
import { ViewBankDetails } from './Partials/ViewBankDetails'

const View_Bank_Details = () => {
    return (
        <div>
            <TopTitle Heading={'View Bank Details'} />
            <CustomCardView width={'1000px'}>
                <ViewBankDetails />
            </CustomCardView>
        </div>
    )
}

export default View_Bank_Details